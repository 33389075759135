<template>
  <div :class="$style.ipInputContainer">
    <div
      v-for="(segment, index) in segments"
      :key="index"
      :class="$style.ipSegment"
    >
      <input
        type="text"
        maxlength="3"
        :class="$style.ipSegmentInput"
        :value="segment"
        :placeholder="placeholder"
        @keydown="onInputKeydown($event, index)"
        @input="onInput($event, index)"
        @blur="onInputBlur()"
        @paste="onPaste($event, index)"
      >
      <i v-show="index != segments.length - 1" :class="$style.ipI">.</i>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
/**
 * get the cursor position of the element
 * @param  {Element} el the element
 * @return {Integer}    the position fo the cursor
 */
function getRange(el) {
  let cuRange;
  let tbRange;
  let headRange;
  let range;
  let dupRange;
  const ret = {};
  if (el.setSelectionRange) {
    // standard
    ret.begin = el.selectionStart;
    ret.end = el.selectionEnd;
    ret.result = el.value.substring(ret.begin, ret.end);
  } else if (document.selection) {
    // ie
    if (el.tagName.toLowerCase() === 'input') {
      cuRange = document.selection.createRange();
      tbRange = el.createTextRange();
      tbRange.collapse(true);
      tbRange.select();
      headRange = document.selection.createRange();
      headRange.setEndPoint('EndToEnd', cuRange);
      ret.begin = headRange.text.length - cuRange.text.length;
      ret.end = headRange.text.length;
      ret.result = cuRange.text;
      cuRange.select();
    } else if (el.tagName.toLowerCase() === 'textarea') {
      range = document.selection.createRange();
      dupRange = range.duplicate();
      dupRange.moveToElementText(el);
      dupRange.setEndPoint('EndToEnd', range);
      ret.begin = dupRange.text.length - range.text.length;
      ret.end = dupRange.text.length;
      ret.result = range.text;
    }
  }
  el.focus();
  return ret;
}
export default {
  props: {
    ip: {
      type: String,
      required: true,
    },
    placeholder: String,
    onChange: Function,
    onBlur: Function,
    id: Number,
    block: Number,
  },
  data() {
    return {
      segments: ['', '', '', ''],
    };
  },
  watch: {
    ip(ip) {
      this.syncIp(ip);
    },
  },
  mounted() {
    this.syncIp(this.ip);
    this.$watch(
      () => this.segments.join('.'),
      (val, oldValue) => {
        if (val !== oldValue) {
          if (val === '...') {
            val = '';
          }
          if (this.onChange) {
            this.onChange(val, this.id, this.block);
          }
        }
      },
    );
  },
  methods: {
    onInputKeydown(event, index) {
      const keyCode = event.keyCode || event.which;
      const { value } = event.target;
      if (keyCode === 8 || keyCode === 37) {
        // move the cursor to previous input if backspace and left arrow is pressed at the begin of one input
        if (
          (value.length === 0 || getRange(event.target).end === 0)
          && index > 0
        ) {
          this.$el.getElementsByTagName('input')[index - 1].focus();
          // When jump to pre input(enter "backspace"), thr cursor should in the end.
          // before fix: 127.|0.0.0  =>   12|7.0.0.1
          // after fix: 127.|0.0.0 = >   127|.0.0.0
          // notes: "|" mean the cursor position.
          event.preventDefault();
        }
      } else if (keyCode === 39) {
        if (getRange(event.target).end === value.length && index < 3) {
          // move to cursor to the next input if right arrow is pressed at the end of one input
          this.$el.getElementsByTagName('input')[index + 1].focus();
        }
      }
    },
    onInput(event, index) {
      const { value } = event.target;
      event.target.value = this.segments[index];
      const segment = Number(value);
      if (isNaN(segment)) {
        return;
      }
      if (value === '') {
        this.segments.splice(index, 1, '');
      } else if (segment > 255 || segment < 0) {
        // set the segment to 255 if out of ip range
        this.segments.splice(index, 1, 255);
      } else {
        this.segments.splice(index, 1, segment);
      }
      // jump to next input
      if (
        (value.length === 3 && index < 3)
        || value[value.length - 1] === '.'
      ) {
        this.$el.getElementsByTagName('input')[index + 1].focus();
      }
    },
    onInputBlur() {
      setTimeout(() => {
        const { className } = document.activeElement;
        if (className.indexOf('ip-segment-input') === -1) {
          if (this.onBlur) {
            this.onBlur(this.segments.join('.'));
          }
        }
      }, 50);
    },
    onPaste(e, index) {
      const pasteText = e.clipboardData.getData('text/plain');
      const segments = pasteText.split('.');
      segments.forEach((segment, i) => {
        if (
          index + i < 4
          && !isNaN(segment)
          && segment >= 0
          && segment <= 255
        ) {
          this.segments.splice(index + i, 1, segment);
        }
      });
      e.preventDefault();
    },
    syncIp(ip) {
      if (ip && ip.indexOf('.') !== -1) {
        ip.split('.').map((segment, index) => {
          if (isNaN(segment) || segment < 0 || segment > 255) {
            segment = 255;
          }
          this.segments.splice(index, 1, segment);
          return segment;
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.ipInputContainer {
  display: inline-block;
  line-height: normal;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  margin-right: 5px;
}
.ipSegment {
  display: inline-block;
  width: 39px;
  height: 26px;
  line-height: normal;
}
.ipSegmentInput {
  width: 30px;
  height: 26px;
  line-height: normal;
  border: none;
  outline: none;
  text-align: center;
  text-indent: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
}

.ipI {
  display: inline-block;
  font-size: 18px;
}
</style>
