/* eslint-disable no-bitwise */
import * as iconv from 'iconv-lite';

const C1 = 52845;
const C2 = 22719;

function move(longInt, length) {
  const mask = 255;
  const numArray = [];
  switch (length) {
    case 1:
    {
      numArray.push(longInt & mask);
      return String.fromCharCode(numArray[0]);
    }
    case 2:
    {
      numArray.push((longInt & mask), ((longInt >> 8) & mask));
      return String.fromCharCode(numArray[0], numArray[1]);
    }
    case 3:
    {
      numArray.push((longInt & mask), ((longInt >> 8) & mask), ((longInt >> 16) & mask));
      return String.fromCharCode(numArray[0], numArray[1], numArray[2]);
    }
    default: return '';
  }
}

function decode(S) {
  const Map = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 62, 0, 0, 0, 63, 52, 53,
    54, 55, 56, 57, 58, 59, 60, 61, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2,
    3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 0, 0, 0, 0, 0, 0, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
    46, 47, 48, 49, 50, 51, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0];
  let I;
  switch (S.length) {
    case 2:
    {
      I = Map[S[0].charCodeAt(0)] + (Map[S[1].charCodeAt(0)] << 6);
      return move(I, 1);
    }

    case 3: {
      I = Map[S[0].charCodeAt(0)] + (Map[S[1].charCodeAt(0)] << 6) + (Map[S[2].charCodeAt(0)] << 12);
      return move(I, 2);
    }

    case 4:
    {
      I = Map[S[0].charCodeAt(0)] + (Map[S[1].charCodeAt(0)] << 6) + (Map[S[2].charCodeAt(0)] << 12)
          + (Map[S[3].charCodeAt(0)] << 18);
      return move(I, 3);
    }
    default: return null;
  }
}

function preProcess(S) {
  let SS = S;
  let result = '';
  while (SS !== '') {
    result += decode(SS.slice(0, 4));
    SS = SS.slice(4);
  }
  return result;
}

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}

function internalDecrypt(S, key) {
  let seed = key;
  let result = S;
  for (let i = 0; i < result.length; i++) {
    result = setCharAt(
      result,
      i,
      String.fromCharCode([...Buffer.from(result[i], 'ascii')][0] ^ (seed >> 8)),
    );
    seed = (([...Buffer.from(S.charAt(i), 'ascii')][0] + seed) * C1 + C2) & 65535;
  }
  return result;
}

export function decrypt(S) {
  const res = internalDecrypt(preProcess(S), 41674);
  return iconv.encode(iconv.decode(Buffer.from(res, 'binary'), 'win1251'), 'utf8').toString();
}
