<template>
  <scrollable-container :class="$style.scrollableContainer">
    <div :class="$style.buttonContainer">
      <button
        :class="$style.button"
        @click="save()"
      >
        Сохранить
      </button>
    </div>
    <container :class="$style.scrollableContent">
      <div
        v-for="(block, index) in settings"
        :key="index"
        :class="$style.card"
      >
        <div
          v-if="index === 9"
          :class="$style.statusContainer"
        >
          <div :class="[$style.tgClass, telegramClass]" />
          <label
            v-if="isBotActive"
            :class="$style.notifLabel"
          >
            Telegram-бот включен
          </label>
          <label
            v-if="!isBotActive"
            :class="$style.notifLabel"
          >
            Telegram-бот выключен
          </label>
        </div>
        <div
          v-for="setting in block"
          :key="setting.id"
          :class="$style.settContainer"
          @click="check(setting.id, setting.block)"
        >
          <div :class="$style.label">
            {{ setting.name }}
          </div>
          <input
            v-if="
              setting.type === 'text' || (setting.id === 14 && showPassword)
            "
            v-model="setting.value"
            type="text"
            :class="[$style.input, $style.longInput]"
          >
          <input
            v-if="
              setting.id === 32 ||
                setting.id === 40 ||
                setting.id === 60 ||
                (setting.id === 14 && !showPassword)
            "
            v-model="setting.value"
            type="password"
            :class="[$style.input, $style.longInput]"
          >
          <img
            v-if="setting.id === 14 && !showPassword"
            title="Показать пароль"
            src="@/assets/images/icons/navigation/hide.svg"
            :class="$style.eyeImg"
            @click="showPassword = true"
          >
          <img
            v-if="setting.id === 14 && showPassword"
            title="Скрыть пароль"
            src="@/assets/images/icons/navigation/show.svg"
            :class="$style.eyeImg"
            @click="showPassword = false"
          >
          <div
            v-if="setting.type === 'number'"
            :class="$style.inline"
          >
            <input
              v-model.number="setting.value"
              type="number"
              :class="$style.input"
            >
            <div
              v-if="setting.additional"
              :class="$style.additional"
            >
              {{ setting.additional }}
            </div>
          </div>
          <toggle-button
            v-if="setting.type === 'bool'"
            v-model="setting.bool"
            :class="$style.toggle"
            :value="setting.bool"
            :sync="true"
            @click.native.prevent
          />
          <date-picker
            v-if="settingsReady && setting.type === 'time'"
            v-model="setting.value"
            type="time"
          />
          <vue-ip-input
            v-if="settingsReady && setting.type === 'ip'"
            :id="setting.id"
            v-model="setting.value"
            :ip="setting.value"
            :on-change="onIpChange"
            :block="setting.block"
          />
        </div>
        <div
          v-if="block[0].id === 64"
          :class="$style.buttonsContainer"
        >
          <button
            :class="[$style.button, $style.buttonBig]"
            :disabled="!isBotActive"
            @click="setTwoFactorAuth(1)"
          >
            Включить двухфакторную аутентификацию для всех
          </button>
          <button
            :class="[$style.button, $style.buttonBig, $style.buttonRed]"
            :disabled="!isBotActive"
            @click="setTwoFactorAuth(0)"
          >
            Выключить двухфакторную аутентификацию для всех
          </button>
        </div>
      </div>
    </container>
  </scrollable-container>
</template>

<script>
import Vue from 'vue';
import { ToggleButton } from 'vue-js-toggle-button';
import DatePicker from 'vue2-datepicker';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import { saveGeneralSettings, getGenSettings } from '@/api/methods/settings';
import { editTwoFactorAuthForAll, checkBot } from '@/api/methods/control';
import VueIpInput from '@/components/common/form/ipInput.vue';
import { decrypt } from '@/helpers/decryption';
import { encrypt } from '@/helpers/encryption';

Vue.component('ToggleButton', ToggleButton);

export default {
  components: { ScrollableContainer, Container, DatePicker, VueIpInput },
  data: () => ({
    isBotActive: false,
    initial: [],
    settingsReady: false,
    showPassword: false,
    encodedFields: [31, 32, 40, 59, 60],
    settings: [
      [
        {
          name: 'Частота опроса агентов',
          value: null,
          type: 'number',
          block: 0,
          id: 2,
          additional: 'мин',
        },
        {
          name: 'Пароль агента по умолчанию',
          value: null,
          type: 'password',
          block: 0,
          id: 14,
        },
      ],
      [
        {
          name: 'Минимальный процент сходства при анализе текста',
          value: null,
          type: 'number',
          block: 1,
          id: 13,
          additional: '%',
        },
        {
          name: 'Запускать обнаружение нестандартного поведения в',
          value: new Date(),
          type: 'time',
          block: 1,
          id: 43,
        },
      ],
      [
        {
          name: 'Время хранения истории',
          value: null,
          type: 'number',
          block: 2,
          id: 4,
          additional: 'дней',
        },
      ],
      [
        {
          name: 'Путь к отчетам на сервере',
          value: null,
          type: 'text',
          block: 3,
          id: 24,
        },
      ],
      [
        {
          name: 'Количество потоков',
          value: null,
          type: 'number',
          block: 4,
          id: 23,
        },
      ],
      [
        {
          name: 'Использовать прокси-сервер',
          value: null,
          type: 'bool',
          block: 5,
          id: 25,
          bool: false,
        },
        {
          name: 'Адрес прокси-сервера',
          value: null,
          type: 'ip',
          block: 5,
          id: 26,
        },
        {
          name: 'Порт прокси-сервера',
          value: null,
          type: 'number',
          block: 5,
          id: 27,
        },
        { name: 'Логин', value: null, type: 'text', block: 5, id: 31 },
        { name: 'Пароль', value: null, type: 'password', block: 5, id: 32 },
      ],
      [
        {
          name: 'Внешний IP сервера',
          value: null,
          type: 'ip',
          block: 6,
          id: 30,
        },
        { name: 'Порт', value: null, type: 'number', block: 6, id: 44 },
      ],
      [
        {
          name: 'Адрес SMTP сервера',
          value: null,
          type: 'text',
          block: 7,
          id: 38,
        },
        {
          name: 'Использовать SSL',
          value: null,
          type: 'bool',
          block: 7,
          id: 42,
          bool: false,
        },
        { name: 'Email Логин', value: null, type: 'text', block: 7, id: 39 },
        { name: 'Пароль', value: null, type: 'password', block: 7, id: 40 },
        { name: 'Порт', value: null, type: 'number', block: 7, id: 41 },
        {
          name: 'Включать в опопвещение всю информацию по событию',
          value: null,
          type: 'bool',
          block: 7,
          id: 33,
          bool: false,
        },
      ],
      [
        {
          name: 'Уведомлять о создании новых ПК и пользователей в Active Directory',
          value: null,
          type: 'bool',
          block: 8,
          id: 58,
          bool: false,
        },
        {
          name: 'Проверять раз в',
          value: null,
          type: 'number',
          block: 8,
          id: 61,
          additional: 'часов',
        },
        { name: 'Логин', value: null, type: 'text', block: 8, id: 59 },
        { name: 'Пароль', value: null, type: 'password', block: 8, id: 60 },
      ],
      [
        {
          name: 'Токен Telegram-бота',
          value: null,
          type: 'text',
          block: 9,
          id: 64,
        },
      ],
    ],
  }),
  computed: {
    telegramClass() {
      if (this.isBotActive) return this.$style.green;
      return this.$style.red;
    },
  },
  async created() {
    await this.checkBot();
    await this.fetch();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/refresh') {
          await this.checkBot();
          await this.fetch();
        }
      },
    });
  },
  methods: {
    onIpChange(ip, id, block) {
      const found = this.settings.find((item) => item[0].block === block);
      const index = found.findIndex((item) => item.id === id);
      this.$set(this.settings[block][index], 'value', ip);
    },
    check(id, block) {
      const found = this.settings.find((item) => item[0].block === block);
      const sett = found.find((item) => item.id === id);
      const index = found.findIndex((item) => item.id === id);
      if (sett.type === 'bool') {
        if (sett.bool) this.$set(this.settings[block][index], 'value', 0);
        else this.$set(this.settings[block][index], 'value', 1);
        this.$set(
          this.settings[block][index],
          'bool',
          !this.settings[block][index].bool,
        );
      }
    },
    async fetch() {
      this.settingsReady = false;
      this.initial = [];
      const sett = await getGenSettings();

      this.settings.forEach((block) => {
        block.forEach((setting) => {
          const found = sett.find((item) => item.id === setting.id);

          if (setting.type === 'time') {
            let time = found.value;
            time = time.split(':');
            setting.value.setHours(time[0]);
            setting.value.setMinutes(time[1]);
            setting.value.setSeconds(time[2]);
          } else {
            if (this.encodedFields.includes(setting.id)) {
              setting.value = decrypt(found.value);
            } else setting.value = found.value;
            if (setting.type === 'bool') {
              if (setting.value === '1') setting.bool = true;
              else setting.bool = false;
            }
          }
        });
      });
      this.initial = JSON.parse(JSON.stringify(sett));
      this.settingsReady = true;
    },
    async checkBot() {
      const result = await checkBot();
      this.isBotActive = result.bot;
    },
    async setTwoFactorAuth(auth) {
      await editTwoFactorAuthForAll(auth);
    },
    async save() {
      const changed = [];
      this.settings.forEach((block) => {
        block.forEach((setting) => {
          const found = this.initial.find((item) => item.id === setting.id);
          if (found.value !== setting.value) {
            if (setting.type === 'number' || setting.type === 'bool') {
              changed.push({ id: setting.id, val: setting.value, sval: '' });
            } else if (setting.type === 'time') {
              const time = `${setting.value.getHours()}:${setting.value.getMinutes()}:${setting.value.getSeconds()}`;
              if (found.value !== time) changed.push({ id: setting.id, val: 0, sval: time });
            } else if (setting.id === 24) {
              changed.push({
                id: setting.id,
                val: 0,
                sval: setting.value.trim(),
              });
            } else if (this.encodedFields.includes(setting.id)) {
              if (encrypt(setting.value) !== found.value) {
                changed.push({
                  id: setting.id,
                  val: 0,
                  sval: encrypt(setting.value),
                });
              }
            } else {
              changed.push({
                id: setting.id,
                val: 0,
                sval: setting.value,
              });
            }
          }
        });
      });
      await saveGeneralSettings(changed);
      await this.checkBot();
      await this.fetch();
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
}

.scrollableContainer {
  padding-bottom: 20px;
}

.input {
  margin: 5px;
  width: 60%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}

.inputContainer {
  font-size: 13px;
  margin: 5px 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.label {
  width: 40%;
  line-height: normal;
}

.button {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 30px;
  width: 127px;
  background: #2985bf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  margin: 10px 40px;
}

.buttonBig {
  height: 45px;
  width: 215px;

  &:disabled {
    background: $branchColor;
    border: $branchColor;
  }
}

.buttonRed {
  background: $red;
}

.buttonsContainer {
  display: flex;
  padding: 10px 20px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;

  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 50px;

  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.longInput {
  width: 40%;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.additional {
  font-size: 14px;
}

.toggle {
  z-index: 0;
}

.tgClass {
  flex: 0 0 12px;
  height: 12px;
  margin: 6px 0 6px 10px;
  border-radius: 50%;

  &.green {
    background: $green;
  }

  &.red {
    background: $red;
  }
}

.statusContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 50px;
}

.notifLabel {
  margin: 0px 10px;
  font-size: 14px;
}

.eyeImg {
  margin-left: -21%;
}
</style>
